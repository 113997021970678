import React from "react";
import starIcon from "../../assets/images/star-icon.png";
import caseStudy1 from "../../assets/images/case-study/case-study1.jpg";
import Loadable from "@loadable/component";
import { useTranslation } from "gatsby-plugin-react-i18next";
const OwlCarousel = Loadable(() => import("react-owl-carousel3"));

const options = {
	loop: true,
	nav: true,
	dots: false,
	autoplayHoverPause: true,
	autoplay: true,
	items: 1,
	navText: [
		"<i class='flaticon-left-SolutionTalentAcquisition'></i>",
		"<i class='flaticon-right-SolutionTalentAcquisition'></i>",
	],
};

const TAb = () => {
	const { t } = useTranslation();

	const [display, setDisplay] = React.useState(false);

	React.useEffect(() => {
		setDisplay(true);
	}, []);

	return (
		<div className="case-study-area bg-fffbf5">
			{display ? (
				<OwlCarousel
					className="case-study-slides owl-carousel owl-theme"
					{...options}
				>
					<div className="single-case-study-item ptb-100">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-6 col-md-12">
									<div className="case-study-content">
										<span className="sub-title">
											<img
												src={starIcon}
												alt="case-study"
											/>
											{t("home.TAbi1")}
										</span>
										<h2>{t("home.TAbh21")}</h2>
										<p>{t("home.TAbp1")}</p>
										<p>{t("home.TAbp2")}</p>

										{/*
                                    <Link to="/case-studies-details" className="default-btn">
                                    <i className="flaticon-view"></i>
                                        Details More
                                        <span></span>
                                    </Link>
                                    */}
									</div>
								</div>

								<div className="col-lg-6 col-md-12">
									<div className="case-study-image">
										{/*
                                    <Link to="/case-studies-details" className="d-block">
                                    */}

										<img
											src={caseStudy1}
											alt="case-study"
										/>

										{/*
                                    </Link>
                                    */}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="single-case-study-item ptb-100">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-6 col-md-12">
									<div className="case-study-content">
										<span className="sub-title">
											<img
												src={starIcon}
												alt="case-study"
											/>
											{t("home.TAbi2")}
										</span>
										<h2>{t("home.TAbh22")}</h2>
										<p>{t("home.TAbp3")}</p>
										<p>{t("home.TAbp4")}</p>

										{/*
                                    <Link to="/case-studies-details" className="default-btn">
                                        <i className="flaticon-view"></i>
                                        Details More
                                        <span></span>
                                    </Link>
                                    */}
									</div>
								</div>

								<div className="col-lg-6 col-md-12">
									<div className="case-study-image">
										{/*
                                    <Link to="/case-studies-details" className="d-block">
                                    */}
										<img
											src={caseStudy1}
											alt="case-study"
										/>
										{/*
                                </Link>
                                    */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</OwlCarousel>
			) : (
				""
			)}
		</div>
	);
};

export default TAb;
