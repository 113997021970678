import React from "react";
import starIcon from "../../assets/images/star-icon.png";
import history1 from "../../assets/images/history/history1.jpg";
import history2 from "../../assets/images/history/history2.jpg";
import history3 from "../../assets/images/history/history3.jpg";
import { useTranslation } from "gatsby-plugin-react-i18next";

const TAa = () => {
	const { t } = useTranslation();

	return (
		<section className="history-area pt-100px pb-100 bg-fafafb">
			<div className="container">
				<div className="section-title">
					<span className="sub-title">
						<img src={starIcon} alt="banner" />
						{t("home.TAai")}
					</span>
					<h2>{t("home.TAah2")}</h2>
				</div>

				<ol className="timeline history-timeline">
					<li className="timeline-block">
						<div className="timeline-date">
							<span>1</span>
							JOB FEED <sup>XML</sup>
						</div>

						<div className="timeline-icon">
							<span className="dot-badge"></span>
						</div>

						<div className="timeline-content">
							<div className="row align-items-center">
								<div className="col-lg-7 col-md-12">
									<div className="content">
										<h3>{t("home.TAah3")}</h3>
										<p>{t("home.TAap")}</p>
									</div>
								</div>

								<div className="col-lg-5 col-md-12">
									<div className="image">
										<img src={history1} alt="banner" />
									</div>
								</div>
							</div>
						</div>
					</li>

					<li className="timeline-block">
						<div className="timeline-date">
							<span>2</span>
							PAGES
							<sup>CARRIERES</sup>
						</div>

						<div className="timeline-icon">
							<span className="dot-badge"></span>
						</div>

						<div className="timeline-content">
							<div className="row align-items-center">
								<div className="col-lg-7 col-md-12">
									<div className="content">
										<h3>{t("home.TAah32")}</h3>
										<p>{t("home.TAap2")}</p>
									</div>
								</div>

								<div className="col-lg-5 col-md-12">
									<div className="image">
										<img src={history2} alt="banner" />
									</div>
								</div>
							</div>
						</div>
					</li>

					<li className="timeline-block">
						<div className="timeline-date">
							<span>3</span>
							JSCRIPT
							<sup>WIDGET</sup>
						</div>

						<div className="timeline-icon">
							<span className="dot-badge"></span>
						</div>

						<div className="timeline-content">
							<div className="row align-items-center">
								<div className="col-lg-7 col-md-12">
									<div className="content">
										<h3>{t("home.TAah33")}</h3>
										<p>{t("home.TAap3")}</p>
									</div>
								</div>

								<div className="col-lg-5 col-md-12">
									<div className="image">
										<img src={history3} alt="banner" />
									</div>
								</div>
							</div>
						</div>
					</li>
				</ol>
			</div>
		</section>
	);
};

export default TAa;
