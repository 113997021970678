import React from 'react';
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"

import WidgetStep from '../components/SolutionTalentAcquisition/Pa1widgetStep'
import Widgetb from '../components/SolutionTalentAcquisition/Pa2widgetb'
import WidgetContact from '../components/SolutionTalentAcquisition/Pa3widgetContact';

const Home = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <NavbarThree />

            <WidgetStep />
            <Widgetb />
            <WidgetContact />

            <Footer />
        </Layout>
    );
}

export default Home;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;